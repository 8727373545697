
.NFTBox {
  height: 100%;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(30deg);
  }
}

@keyframes pulse {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(30deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.NFTClaimRootBox {
  position: absolute;
  left: 50%;
  margin: auto;
  transform: translate(-50%,10%);
  max-height: 80%;
}

.NFTImage {
  width: 287px;
  height: 409px;
  animation: pulse 3s infinite 2s reverse backwards;
  left: 50%;
}

.NFTImageSpotlight {
  width: 311px;
  height: 217px;
  margin-top: -90px;
}

.NFTClaimButton {
    position: fixed;
    left: 50%;
    bottom: 10%;
    /* width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    background-image: url(../../public/ClaimNowButton.svg);
    background-size: 100% 100%;
    width: 203px;
    height: 65px;
    transform: translate(-50%,0); */
    /* margin-left: 50%;
    transform: translate(-50%,0);
    margin-bottom: 10px; */

    width: 13%;
    /* height: 60px; */
    cursor: pointer;
    /* left: 50%;
    top: 16%; */
    transform: translate(-50%,0);
}

.StartButtonRoadmap {
  position: absolute;
  width: 13%;
  /* height: 60px; */
  cursor: pointer;
  left: 50%;
  top: 16%;
  transform: translate(-50%,0);
}