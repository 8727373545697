
.TroveMateBuilderContainer {
    z-index: 2;
    position: fixed;
    display: block;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.TroveMateBuilderRoot {
    background-color: white;
    position: absolute;
    padding: 20px;
    padding-bottom: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.TroveMateBuilderHeader {
    color: #F15946;
    text-decoration: none;
    font-size: 40px;
    font-weight: bold;
    font-family: Urbanist-Bold;
}

.TroveMateBuilderSubHeader {
    color: #F15946;
    padding-bottom: 10px;
    text-decoration: none;
    font-size: 16px;
    font-family: Urbanist-Thin;
}

.TroveMateBuilderInfoBox {
    /* width: 350px; */
    /* height: 70%; */
    /* background-color: #8a8f8e41; */
    /* border-radius: 10px; */
    padding: 10px;
    /* margin-top: 20px; */
    /* margin-bottom: 20px; */
    margin-right: 10px;
    align-items: center;
    align-content: center;
}

.TroveMateBuilderInfoBox h1 {
    color: #797997;
    font-size: 16px;
    margin-left: 10px;
    font-weight: 500;
}

.TroveMateBuilderInfoBox h2 {
    color: #797997;
    font-size: 16px;
    margin-left: 10px;
    font-weight: 500;
}


.BuildLeftBox h2 {
    font-size: 16px;
    /* font-family: Urbanist-Light; */
    color: #3C4443;
}

.backButton {
    background-color: red;
    position: absolute;
    border-radius: 20px;
    left: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
}

.IncDecButton {
    height: 50px;
    width: 50px;
    color: #F15946;
    border-radius: 10px;
    font-weight: bolder;
    font-size: xx-large;
    border: 0;
    cursor: pointer;

}

.IncDecInput {
    height: 50px;
    width: 70px;
    font-size: 32px;
    font-family: Urbanist-Bold;
    color: #F15946;
    border-radius: 10px;
    text-align: center;
    padding-top: 10px;
}

.IncDecBox {
    margin-top: 10px;
    width: 100%;
    justify-content: center;
    vertical-align: middle;
    display: flex;
}

.buildButtonBox {
    width: 320px;
    justify-content: center;
    vertical-align: middle;
    /* display: flex; */
}

.TMBBuildButton {
    margin-top: 30px;
     background-color: #F15946;
     border-radius: 10px;
     align-items: center;
      vertical-align: middle;
      justify-content: center; 
      text-decoration: none;
      font-size: 28px;
      font-weight: bold;
      color: white;
      text-align: center;
      vertical-align: middle;
      padding: 15px;
      min-width: 200px;
      cursor: pointer;

  }