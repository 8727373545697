.WalletBox {
    width: 100%;
    border-spacing: 25px 0;
}

.walletContainer {
    width: 210px;
    height: 210px;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 20px;
    vertical-align: middle;
    justify-content: center;
    margin-right: 10px;
    margin-bottom: 10px;
}

.walletTitle {
    font-size: 28px;
    font-weight: bold;
    font-family: Urbanist-Bold;
    color: #F15946;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    padding-top: 70px;
}

.walletSubTitle {
    font-size: 20px;
    font-family: Urbanist-SemiBold;
    color: black;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    padding-top: 7px;
}