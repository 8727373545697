.LoginViewContainer {
    position: fixed;
    display: block;
    /* top: 0px;
    right: 0px; */
    width: 100%;
    height: 100%;
    vertical-align: middle;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.LoginViewRoot {
    background-color: #0F6359;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    width: 420px;
    height: 270px;
    justify-content: center;
    align-items: center;
    border: 1px solid #0F897A;
    background: linear-gradient(to bottom right, #0F6359, #12403B);
}

.popopButtonImageWax {
    width: 380px;
    padding-top: 65px;
    background-color: transparent;
    background-image: url(../../public/WaxCloud.svg);
    background-size: 100% 100%;
    margin-bottom: 10px;
    margin-left: 15px;
    cursor: pointer;
}

.popopButtonImageAnchor {
    width: 380px;
    padding-top: 65px;
    background-color: transparent;
    background-image: url(../../public/Anchor.svg);
    background-size: 100% 100%;
    margin-bottom: 10px;
    margin-left: 15px;
    cursor: pointer;
}

.popopButtonImageMetamask {
    width: 380px;
    padding-top: 65px;
    background-color: transparent;
    background-image: url(../../public/Metamask.svg);
    background-size: 100% 100%;
    margin-left: 15px;
    cursor: pointer;
}

.LoginViewRoot h1 {
    width: 100%;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 26px;
    font-weight: 400;
    /* font-family: Urbanist-SemiBold; */
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    margin-left: 7px;
    margin-bottom: 35px;
    margin-top: 25px;
}
