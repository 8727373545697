.FAQPageContainer {
    z-index: 2;
    position: fixed;
    display: block;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.FAQPageRoot {
    background-color: white;
    position: absolute;
    padding: 20px;
    padding-bottom: 30px;
    left: 10%;
    top: 10%;
    height: 85%;
    transform: translate(-5%, -5%);
    border-radius: 20px;
    /* height: 100%;  */
    overflow-y: scroll; 
}

.FAQPageHeader {
    color: #F15946;
    padding-bottom: 30px;
    text-decoration: none;
    font-size: 40px;
    font-weight: bold;
    font-family: Urbanist-Bold;
}