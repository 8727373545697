.NFTTitle {
    color: white;
    padding-bottom: 10px;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
}

.NFTsListClass {
    /* background-color: white; */
    overflow: hidden;
}