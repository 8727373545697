.FactoryBox {
    width: 100%;
    border-spacing: 25px 0;
}

.factoryCardContainer {
    background-color: #00322C;
    border-radius: 8px;
    width: 200px;
    height:270px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    position: relative;
}

.factoryCardText {
    text-decoration: none;
    color: white;
    font-size: 22px;
    font-family: Urbanist-Bold;
}