


.troveMatePageContainer {
    padding: 15px;
    padding-top: 60px;
}

.MTPPageHeader {
    color: white;
    padding-bottom: 30px;
    text-decoration: none;
    font-size: 40px;
    font-weight: bold;
    font-family: Urbanist-Bold;
}

.SkinContainerColumn {
    position: absolute;
    bottom: 0px;
}

.TroveMateBack {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    height: 260px;
    width: 178px;
}

.TroveMateNameLabel {
    color: black;
    font-weight: bold;
    padding-top: 7px;
    padding-left: 2px;
}

.TMPBuildCellImage {
    width: 45px;
    height: 45px;
    margin-left: 50%;
    margin-top: 50%;
    transform: translate(-50%, -50%);
}

.TMPBuildCell {
    border-radius: 10px;
    text-align: center;
    display: flex;
    position: fixed;
    z-index: -1;

    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 24px;
    border: 1px solid #0F897A;
    background-color: #0F6359;
}

.TMPBuyCell {
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F15946;
    font-weight: bold;
    font-size: larger;
    margin-top: 25px;
}

.TrovematePageBuySkin {
    /* background-color: #F15946; */
    border-radius: 4px;
    margin: 18px;
    text-align: left;
    padding: 14px;
    min-width: 210px;
    margin-left: 0px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
    text-decoration: underline;
}

.CounterView {
    position: absolute;
    width: 34px;
    height: 34px;
    justify-content: center;
    color: white;
    font-weight: bold;
    border-radius: 34px;
    font-size: 26px;
    padding: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #F15946;
    top: -24px;
    right: -10px;
}