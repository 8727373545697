.TroveMateBox {
    /* background-color: red; */
    /* border-radius: 10px; */
    /* padding: 2%; */
    /* margin-bottom: 10px; */
    /* display: flex; */
    /* max-width: fit-content;
    max-height: fit-content; */
    overflow: hidden;
    justify-content: left;
    border-top-left-radius: 20px;
    background-color: black;

}

.TroveMateImage {
    overflow: hidden;
    max-width: 151%;
    max-height: 100%;
    /* transform: translate(-18%, 0%); */
}

.SkinBox {
    width: 60px;
    height: 60px;
    left: 5px;
    top: 5px;
    position:absolute;
    /* transform: translate(-50%, 0); */
    /* background-color: white; */
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    /* overflow: hidden; */
}

.SkinImage {
    /* background-color: white; */
    /* width: 60px; */
    height: 60px;
    /* border-radius:50%;
    box-shadow: 0 0 0 3px white; */
    /* border-radius: 30px; */
    /* left: 50%;
    top: 50%; */
    /* position:absolute; */
    /* border: 1px solid #ff0000 !important; */
}