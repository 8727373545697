.CoverPage {
    position: fixed;
    display: flex;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    background-color: #13443E;
    background-image: url(../../public/LandingPage2.svg);
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
}

.CoverPageHeader {
    position: fixed;
    right: 20px;
    top: 25px;
    color: white;
    background-color: transparent;
    border: thin solid green;
    padding: 5px;
}

.CoverPageHeaderHRef {
    font: 700;
    color: white;
    text-decoration: none;
    letter-spacing: 3px;
}

.CoverTitleText {
    color: white;
    font-size: 28px;
    font-weight: 600;
}

.CoverPageContent {
    margin-top: -650px;
}

.CoverPageFooter {
    position: fixed;
    left: 0;
    bottom: 10px;
    width: 100%;
    background-color: transparent;
    color: white;
    text-align: center;
}

.CoverPageFooterImage {
    margin:20px;
    cursor: pointer;
}

.loginButton {
    /* position: fixed;
    display: block; */
    width: 190px;
    height: 60px;
    font-size: 30px;
    font-family: Urbanist-Bold;
    border: 0px;
    color: #F15946;
    background-color: transparent;
    background-image: url(../../public/buttonBack.svg);
    background-repeat: no-repeat;
    cursor: pointer;

    /* background-size: 100% 100%; */
}