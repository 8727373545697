.TroveMateViewerContainer {
    z-index: 2;
    position: fixed;
    display: block;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
align-items: center;
justify-content: center;
}

.TroveMateViewerRoot {
    background-color: rgba(255, 255, 255, 1.0);
    position: absolute;
    /* left: 50%;
    top: 50%; */
    margin: auto;
    /* transform: translate(-50%, -50%); */
    border-radius: 10px;
}

.TMObjContainer {
    width: 100%;
    height: 100%;
    display: block;
    padding-bottom: 24px;
    padding-left: 5px;
    padding-right: 5px;
}

.ObjRoot {
    top: 0;
    left: 0;
    margin: 10px;
    z-index: 2;
    position: fixed;
    display: block;
}

.troveMateViewerContainerBox {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    /* min-width: 100px;
    min-height: 100px; */
    margin: 20px;
    display: block;
}

.troveMateBig {
    background-color: #6DB137;
    /* margin: 8px; */
    border-radius: 10px;
    max-width: 400px;
    max-height: 400px;
}

.TroveMateInfoBox {
    margin-top: 0px;
    /* margin-left: 10px;
    margin-right: 10px; */
    margin-bottom: 10px;
    /* background-color: #8a8f8e41; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-top: 1px;
    /* min-width: 380px; */
}

.EditorBox {
    margin-top: 20px;
    margin-right: 20px;
    align-items: center;
    vertical-align: middle;
    align-content: center;
    justify-content: center;
}

.ColorBox {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 25px;
    align-items: center;
    vertical-align: middle;
    align-content: center;
    justify-content: center;
}

.SkinsContainer {
    background-color: peachpuff;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 6px;
}

.mySkinContainer {
    width: 50px;
    height: 50px;
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    align-items: center;
    vertical-align: center;
    align-content: center;
    /* margin-bottom: 8px; */
}

.TroveMateViewerSkinContainer {
    background-color: #3A7770;
    width: 395px;
    height: fit-content;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: -5px;
    padding-top: 10px;
}

.tmSkinContainer {
    width: 80px;
    height: 100px;
    background-color: #3A7770;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 8px;
    align-items: center;
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
}

.skinContainer {
    width: 60px;
    height: 120px;
    background-color: #3A7770;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    margin-bottom: 8px;
}

.skinPriceLabel {
    font-size: small;
    color: white;
    text-align: center;
}

.skin {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 6px;
    padding: 5px;
    margin: 10px;
}

.MenuItemsForEditor h1 {
    font-size: 17px;
    font-weight: 600;
    color: white;
    text-align: center;
    cursor: pointer;
}

.ColorBox h1 {
    font-size: 20px;
    font-weight: 500;
    color: #3A7770;
}

.EditorBox h1 {
    font-size: 20px;
    font-weight: 500;
    color: #3A7770;
}

.TroveMateInfoBox h1 {
    margin-top: 10px;
    font-size: 26px;
    font-weight: bold;
    color: #3A7770;
    text-align: left;
}

.TroveMateInfoBox h2 {
    margin-top: -15px;
    font-size: 14px;
    color: black;
}

.NoSkinsLabel {
    margin-left: 15px;
    color: #3A7770;
    margin-right: 5px;
}

.BuySkinButton {
    background-color: #F15946;
    border-radius: 4px;
    margin: 5px;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

.ClaimButton {
    font-size: 18px;
    right: 0px;
    top: 5px;
    position: absolute;
    width: 180px;
    background-color: #F15946;
    color: white;
    border-radius: 10px;
    margin: 20px;
    padding: 12px;
    text-align: center;
    font-weight: 800;
    cursor: pointer;
}

.EditButton {
    font-size: 18px;
    background-color: #6DB137;
    color: white;
    border-radius: 10px;
    margin: 20px;
    padding: 12px;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
}

.backButton {
    z-index: 5;
    background-color: red;
    position: absolute;
    border-radius: 20px;
    left: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}