.ProfileContainer {
    z-index: 2;
    position: fixed;
    display: block;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    /* vertical-align: middle; */
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.ProfilePage {
    width: 260px;
    height: 150px;
    background-color: white;
    z-index: 2;
    position: fixed;
    display: block;
    top: 100px;
    right: 50px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.ProfilePageHeader {
    color: #3A7770;
    padding-bottom: 30px;
    text-decoration: none;
    font-size: 32px;
    font-weight: bold;
    font-family: Urbanist-Bold;
    padding-left: 18px;
    padding-top: 14px;
}

.ProfilePageTitle {
    color: #3A7770;
    padding-bottom: 10px;
    text-decoration: none;
    font-size: 18px;
    font-family: Urbanist-SemiBold;
    padding-left: 18px;
}

.ProfilePageFooter {
    width: 203px;
    height: 65px;
    background-color: transparent; 
    border: 0px;
    border-radius: 0px;
    background-position: center;
    position: absolute;
    text-decoration: none;
    text-align: left;
    font-size: 18px;
    font-family: Urbanist-SemiBold;
    color: gray;
    margin-top: 0px;
    padding-left: 18px;
  }