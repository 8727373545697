.BuySkinPageContainer {
    z-index: 2;
    position: fixed;
    display: block;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.BuySkinPageRoot {
    background-color: white;
    position: absolute;
    padding: 20px;
    padding-bottom: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.BuySkinPageHeader {
    color: #F15946;
    padding-bottom: 30px;
    text-decoration: none;
    font-size: 40px;
    font-weight: bold;
    font-family: Urbanist-Bold;
}

.BuySkinContainer {
    width: 130px;
    height: 180px;
    background-color: #3A7770;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    margin-bottom: 8px;
}

.BuySkinPriceLabel {
    font-size: medium;
    color: white;
    text-align: center;
}

.BuyImageClass {
    width: 130px;
    height: 130px;
}

.BuySkinButtonClass {
    background-color: #F15946;
    border-radius: 4px;
    color: white;
    font-weight: 800;
    text-align: center;
    padding: 10px;
    margin-top: 7px;
    cursor: pointer;
}