.SideMenuBox {
    height: 100%;
    max-width: fit-content;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    padding: 10px;
    padding-left: 18px;
}

.SideMenuRootBox {
    width: 300px;
}

.SideMenuBuySkin {
    background-color: #F15946;
    border-radius: 4px;
    margin: 5px;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

/* .pro-sidebar > .pro-sidebar-inner {
    color: white;
    background-color: rgba(0, 0, 0, 0.0);
    top: 50px;
    left: 10px;
}

.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 16px; 
    font-weight: bold;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    height: 85vh;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    font-size: 20px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    line-height: 40px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    outline: none;
    color: #F15946; 
} */