/* .TroveMateInfoContainer {
    z-index: 2;
    position: fixed;
    display: block;
    top: 9%;
    right: 0px;
    width: 100%;
    height: 85%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.0);
    display: flex;
    align-items: center;
    justify-content: center;
} */

.TroveMateInfoContainer {
    padding: 15px;
    padding-top: 60px;
    height: 100%;
    justify-content: center;
}

.TroveMateInfoRoot {
    background-color: #0B2D29;
    position: absolute;
    /* left: 50%; */
    left: 50%;
    top: 50%;
    margin: auto;
    margin-top: 0px;
    transform: translate(-50%, -50%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 5px;
    box-shadow:         inset 0 0 10px #000000;
}

.TroveMateInfoRootLeftArrow {
    background-color: rgba(19, 70, 10, 0.1);
    position: absolute;
    margin: auto;
    width: 50px;
    height: 50px;
    left: 10px;
    cursor: pointer;
    border-radius: 25px;
    box-shadow:         inset 0 0 6px rgba(19, 70, 10, 0.3);
    top: 50%;
    transform: translate(0%, -50%);

}

.TroveMateInfoRootRightArrow {
    background-color: rgba(19, 70, 10, 0.1);
    position: absolute;
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    right: 10px;
    top: 50%;
    cursor: pointer;
    box-shadow:         inset 0 0 6px rgba(19, 70, 10, 0.3);
    transform: translate(0%, -50%);

}

.NoTrovemateInfo {
    color: white;
    font-size: xx-large;
    font-weight: bold;
    padding-top: 50px;
}

.TrovemateInfoRootLeftArrowImage {
    margin-left: 14px;
    margin-top: 6px;
}

.TrovemateInfoRootRightArrowImage {
    margin-left: 18px;
    margin-top: 6px;
}

.TMObjInfoStarBox {
    color: white;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    margin-left: 250px;
    transform: translate(-50%, -50%);
}

.TMObjInfoHeaderBox {
    color: #F3EC53;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    margin-left: 250px;
    transform: translate(-50%, -100%);
    background-color: rgb(15, 32, 12);
    padding: 5px;
    padding-left: 14px;
    padding-right: 14px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 0.8rem;
    font-weight: 900;
    box-shadow:          0 0 4px #113C57;
    text-transform: uppercase;

}

.TMObjInfoContainer {
    width: 100%;
    height: 100%;
    display: block;
    border-top-left-radius: 20px;
    background-color: #F3EC53;
    /* -moz-box-shadow:    inset 0 0 10px #051614;
    -webkit-box-shadow: inset 0 0 10px #051614; */
    box-shadow:         0px 0px 2px 2px #071f1c;
}

.TroveMateInformationRoot {
    /* width: 93%; */
    /* background-color: #0F897A; */
    height: 450px;
    /* margin-left: -15px;
    border-radius: 10px;*/
    padding-left: 30px; 
}

.TroveMateInformationRootTitle {
    color: #F26936;
    font-size: 4.3rem;
    text-transform: uppercase;
    font-weight: 800;
}

.TroveMateInfoRootTitlePart {
    color: white;
    font-size: 4.3rem;
    text-transform: uppercase;
    font-weight: bold;
    padding-right: 14px;
}

.TroveMateInfoRootSubTitle {
    color: white;
    font-size: 1.2rem;
    max-width: 300px;
}

.TroveMateInfoRootTile {
    color: white;
    font-size: 1rem;
    background-color: rgb(55, 90, 83);
    width: intrinsic;           /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content;    /* Firefox/Gecko */
    width: -webkit-max-content; /* Chrome */
    width: max-content;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 10px;
    box-shadow:        inset  0 0 4px #113C57;

}

/* @media all and (min-device-width: 720px){
    TroveMateInfoRootTitlePart {
        font-size: 18px;
    }
}

@media all and (max-device-width: 640px){
    TroveMateInfoRootTitlePart {
        font-size: 16px;
    }
}

@media all and (max-device-width: 320px){
    TroveMateInfoRootTitlePart {
        font-size: 14px;
    }
} */

.TroveMateInfoRootTileID {
    background-color: #F3EC53;
    font-size: 1.2rem;
    color: black;
    width: intrinsic;           /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content;    /* Firefox/Gecko */
    width: -webkit-max-content; /* Chrome */
    width: max-content;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 5px;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 20px;
    box-shadow:        inset  0 0 4px #9e9820;


}