.App {
    background-color: #13443E;
    height: 100%;
    width: 100%;
    position: fixed;
    background: linear-gradient(to top right, #021310, #0E3834);

    /* background-image: url(./../public/back1x.png); */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
}

/* .AppBackground { */
    /* position: fixed; */
    /* height: 100%;
    width: 100%; */
    /* background-image: 'back1x.png';
    background-repeat: no-repeat; */
    /* background-size: cover; */
/* } */

.AppContainer {
    position: absolute;
    /* display: flex; */
    right: 5%;
    top: 15px;
    border-radius: 20px;
    width: 90%;
    /* padding-right: 20px; */
    /* margin-right: 150px; */
    height: 95%;
}

.AppBody {
    display: flex;
    flex-direction: row;
    position: relative; 
    height: 80vh;
    padding: 20px;
}

.topBar {
    /* min-width: 100%; */
    display: flex;
    flex-direction: row;
    /* padding: 30px; */
    padding: 30px;
    position: relative;
    padding-bottom: 0px;
}

.MenuBar {
    position: relative;
}

.TopMenuItemFAQ {
    color: white;
    position: absolute;
    right: 120px;
    top: 50px;
    font-weight: bold;
    font-size: medium;
    text-decoration:none;
}

.TopMenuItemWhitepaper {
    color: white;
    position: absolute;
    right: 120px;
    top: 50px;
    font-weight: bold;
    font-size: medium;
    text-decoration:none;
}

.AppMainFooter {
    position: fixed;
    left: 0;
    bottom: 0px;
    width: 100%;
    background-color: transparent;
    color: white;
    text-align: center;
}

.AppMainFooterImage {
    margin:20px;
    cursor: pointer;
}

.TopMenuItemCLAIM {
    color: white;
    position: absolute;
    right: 200px;
    top: 50px;
    font-weight: bold;
    font-size: medium;
    text-decoration:none;
}

/* .AppMainTable {
    height: 100%;
    width: 100%;
    position:relative;
}

table tr th:nth-child(1){
    width: 50%;
  } */

.AppFooter {
    min-width: 100%;
    display: flex;
    padding-top: 10px;
    bottom: 0px;
    background-color: #13443E;
}

.AppRingsBack {
    position: fixed;
    right: 0;
    bottom: 0px;
    /* width: 100%; */
    /* transform: translate(50%,0px); */
    background-color: transparent;
    color: white;
    text-align: center;
    z-index: -10;
    opacity: 0.5;
}

.App-logo {
    pointer-events: none;
    height: 100%;
    margin-top: 10px;
}

.accountInfo {
    margin-left: auto;
    display: flex;
    height: 100%;
}
/* 
@font-face {
    font-family: Urbanist-Bold;
    src: url('./../public/Urbanist/static/Urbanist-Bold.ttf');
} */