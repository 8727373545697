.RoadmapContainerBox {
    z-index: 2;
    position: fixed;
    display: block;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
}

.RoadmapChildBox {
    background-color: white;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    overflow-y: scroll; 
}

.LoginBoxForRoadmap {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.RoadmapImage {
    display: block;
    margin: auto;
    height: auto;
    width: 100%;
}

.RoadmapFooter {
    position: fixed;
    left: 0;
    bottom: 45px;
    width: 100%;
    background-color: transparent;
    color: white;
    text-align: center;
}

.RoadmapFooterPurple {
    position: fixed;
    left: 0;
    bottom: 20px;
    width: 100%;
    background-color: transparent;
    color: white;
    text-align: center;
    cursor: pointer;
}

.RoadmapFooterImage {
    margin:20px;
    cursor: pointer;
}

.RoadmapHeader {
    position: fixed;
    right: 20px;
    top: 25px;
    color: white;
    background-color: transparent;
    border: thin solid green;
    padding: 5px;
}

.RoadmapHeaderHRef {
    font: 700;
    color: white;
    text-decoration: none;
    letter-spacing: 3px;
}

.RoadmapTopView {
    position: relative;

}
.RoadmapLoginButton {
    position: absolute;
    width: 190px;
    height: 60px;
    font-size: 30px;
    font-family: Urbanist-Bold;
    border: 0px;
    color: #F15946;
    background-color: transparent;
    background-image: url(../../public/buttonBack.svg);
    background-repeat: no-repeat;
    cursor: pointer;
    left: 50%;
    top: 20%;
    transform: translate(-50%,0);
    /* background-size: 100% 100%; */
}


.StartButtonRoadmap {
    position: absolute;
    width: 13%;
    /* height: 60px; */
    cursor: pointer;
    left: 50%;
    top: 16%;
    transform: translate(-50%,0);
}